<template>
  <div class="wrapper">
    <div
      v-if="!isFetchingItem && item"
      :class="[`main-grid`, toggleFilter ? 'sidebar-open' : '']"
    >
      <ItemTile
        :source="'inv'"
        :size="'large'"
        :item="item"
        :isMarketplace="true"
      />
      <div class="details">
        <h1>{{ item.name }}</h1>
        <span :class="['rarity', item?.rarity.toLowerCase()]">
          {{ item.rarity }}
        </span>
        <span
          :class="['price', currentCategory !== 'equipment' ? 'DCAR' : 'DCAU']"
          >{{ item.priceInDCAU }}
          <span>{{ currentCategory !== "equipment" ? "DCAR" : "DCAU" }}</span>
          <template v-if="isSessionActive"
            ><span v-if="!isLoadingDollarPrice" class="dollar-value"
              >(${{ currentPriceDollar }})</span
            >
            <SkeletalLoading v-else width="40" /></template
        ></span>

        <div
          class="button-container single-marketplace-item"
          v-if="isSessionActive"
        >
          <BlueButton
            v-if="!isDCAUApproved && currentCategory === 'equipment'"
            :size="'small'"
            @click="approveDCAU"
            :isLoading="isApprovingDCAU"
          >
            Approve DCAU</BlueButton
          >
          <BlueButton
            v-else-if="!isDCARApproved && currentCategory !== 'equipment'"
            :size="'small'"
            @click="approveDCAR"
            :isLoading="isApprovingDCAR"
            >Approve DCAR</BlueButton
          >
          <template v-else>
            <BlueButton
              v-if="!isOwner"
              :size="'small'"
              @click="processBuy"
              :isLoading="isBuying"
              >Buy</BlueButton
            >
            <BlueButton
              v-else
              :size="'large'"
              @click="cancelListing"
              :isLoading="isRemoving"
              >Cancel Listing</BlueButton
            >
          </template>
        </div>
        <div class="attributes">
          <div :class="['mini-popup']">
            <div class="meta quantity" v-if="item.quantity">
              <span class="key">Quantity</span
              ><span class="value">{{ item.quantity }}</span>
            </div>
            <div class="meta weapon" v-if="item.weaponType">
              <span class="key">Type</span>
              <span class="value">{{ item.weaponType }}</span>
            </div>
            <div class="meta attack" v-if="item.attack">
              <span class="key">Attack</span>
              <span class="value">{{ item.attack }}</span>
            </div>
            <div class="meta defense" v-if="item.defense">
              <span class="key">Defense</span>
              <span class="value">{{ item.defense }}</span>
            </div>
            <div class="meta magic" v-if="item.magic">
              <span class="key">Magic</span>
              <span class="value">{{ item.magic }}</span>
            </div>
            <div class="meta hp-bonus" v-if="item.totalHp">
              <span class="key">HP</span>
              <span class="value">{{ item.totalHp }}</span>
            </div>
            <div class="meta durability" v-if="item.durability">
              <span class="key">Durability</span>
              <span class="value"
                >{{ item.durability }}/{{ item.totalDurability }}</span
              >
            </div>
            <div class="meta crafting" v-if="item.craftingRequired">
              <span class="key">Crafting</span>
              <span class="value">{{ item.craftingRequired }}</span>
            </div>
          </div>
          <div class="mini-popup">
            <div class="meta owner" v-if="item.quantity">
              <span class="key">Owner</span>
              <span class="value">
                {{ item.ownerAddress.substring(0, 4) }}...{{
                  item.ownerAddress.substring(
                    item.ownerAddress.length - 4,
                    item.ownerAddress.length
                  )
                }}
              </span>
            </div>
            <div class="meta contract" v-if="item.quantity">
              <span class="key">Contract</span>
              <span class="value">
                {{ item.ownerAddress.substring(0, 4) }}...{{
                  item.ownerAddress.substring(
                    item.ownerAddress.length - 4,
                    item.ownerAddress.length
                  )
                }}
              </span>
            </div>
            <div class="meta broken" v-if="item.isBroken !== undefined">
              <span class="key">Is Broken</span>
              <span class="value">
                {{ item.isBroken }}
              </span>
            </div>
            <div
              class="meta health"
              v-if="item.durability && item.totalDurability"
            >
              <span class="key">Health</span>
              <span class="value">
                {{ item.durability }}/{{ item.totalDurability }}
              </span>
            </div>
          </div>
          <div class="meta"></div>
        </div>
      </div>
    </div>
    <GenericLoading v-else />
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import SkeletalLoading from "../../components/LoadingComponents/SkeletalLoading.vue";
import { usePrice } from "../../composables/price";
import { useUser } from "../../composables/user";
import Constants from "../../consts/constants";
import { parseError } from "../../utils/helpers";
// import { useRoute } from "vue-router";
import { ethers } from "ethers";
import ItemTile from "../../components/AdvancedMarketplace/ItemTile.vue";
import BlueButton from "../../components/Buttons/BlueButton.vue";
import { useMixpanel } from "../../composables/mixpanel";

import {
  getDCARContract,
  getDCAUContract,
  getLoadMarketplaceContract,
} from "../../utils/getContract";
import GenericLoading from "../LoadingComponents/GenericLoading.vue";

export default {
  components: {
    BlueButton,
    SkeletalLoading,
    ItemTile,
    GenericLoading,
  },
  props: {
    isOwner: { type: Boolean, default: false },
  },
  setup() {
    const route = useRoute();
    const error = ref(null);
    const joepegs = ref(null);
    const heroData = ref(null);
    const hasId = ref(false);
    const id = ref("");
    const isLoading = ref(false);
    const store = useStore();
    const toggleFilter = ref(false);
    const searchSorting = ref("Recent");
    const toggleDropdown = ref(false);

    const isDCAUApproved = ref(false);
    const isDCARApproved = ref(false);
    const isApprovingDCAU = ref(false);
    const isApprovingDCAR = ref(false);
    const isBuying = ref(false);
    const isLoadingDollarPrice = ref(false);

    // Mixpanel Tracking
    const { trackEvent } = useMixpanel();

    const dcauPerDollar = ref(0);
    const dcarPerDollar = ref(0);
    const currentPriceDollar = ref(0);
    const { address, signer } = useUser();
    const { getDCAUPricePerDollar, getDCARPricePerDollar, updateBalances } =
      usePrice();
    // const rpcProvider = new ethers.providers.JsonRpcProvider(Constants.rpcUrl);

    const DCAUContract = ref(null);
    const DCARContract = ref(null);
    const MarketplaceContract = getLoadMarketplaceContract(signer.value);
    const item = ref(null);

    const isFetchingItem = ref(false);
    const currentSearchTerm = ref("");
    const searchDelayTimer = ref(null);

    const sortList = [
      "Recent",
      "Oldest",
      "Price Desc",
      "Price Asc",
      "Rarity Desc",
      "Rarity Asc",
      "Attack Desc",
      "Defense Desc",
      "Magic Desc",
      "Speed Desc",
      "Crafting Desc",
      "Gathering Desc",
      "Health Desc",
    ];

    // const nftTrade = ref(null);
    // const kalao = ref(null);

    // const route = useRoute();

    onMounted(async () => {
      // await getMarketItems();
      await fetchItem();
      if (isSessionActive.value) {
        // Loading Contracts
        DCAUContract.value = getDCAUContract(signer.value);
        DCARContract.value = getDCARContract(signer.value);
        // Checking Token Approvals
        await checkIsDCAUApproved();
        await checkIsDCARApproved();
        // Fetching Dollar Value
        isLoadingDollarPrice.value = true;
        dcauPerDollar.value = await getDCAUPricePerDollar();
        dcarPerDollar.value = await getDCARPricePerDollar();
        // dcauPerDollar.value = await getDCAUPricePerDollarViaProvider(
        //   rpcProvider
        // );
        // console.log(dcauPerDollar.value);
        // dcarPerDollar.value = await getDCARPricePerDollarViaProvider(
        //   rpcProvider
        // );
        await updateCurrentPriceDollar();
        isLoadingDollarPrice.value = false;
      }
    });

    store.watch(
      () => store.state.isSessionActive,
      async (isSessionActive) => {
        if (isSessionActive) {
          DCAUContract.value = getDCAUContract(signer.value);
          DCARContract.value = getDCARContract(signer.value);
          await checkIsDCAUApproved();
          await checkIsDCARApproved();
          isLoadingDollarPrice.value = true;
          dcauPerDollar.value = await getDCAUPricePerDollar();
          dcarPerDollar.value = await getDCARPricePerDollar();
          await updateCurrentPriceDollar();
          isLoadingDollarPrice.value = false;
        }
      }
    );

    /**
     * Methods
     */
    const fetchItem = async () => {
      isFetchingItem.value = true;
      try {
        const id = route.params.id;
        const path = `${Constants.apiUrl}marketplace/get-item?id=${id}`;
        const response = await fetch(path, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const result = await response.json();
        if (result.success) {
          item.value = result.marketplaceItem;
        }
      } catch (error) {
        console.log(error);
      }
      isFetchingItem.value = false;
    };
    // Helper method to delay stuff
    const delay = async (time) => new Promise((res) => setTimeout(res, time));
    const updateCurrentPriceDollar = async () => {
      await delay(250);
      if (currentCategory.value === "equipment") {
        currentPriceDollar.value = (
          parseFloat(item.value.priceInDCAU) / parseFloat(dcauPerDollar.value)
        ).toFixed(2);
      } else if (
        currentCategory.value === "resource" ||
        currentCategory.value === "consumable"
      ) {
        currentPriceDollar.value = (
          parseFloat(item.value.priceInDCAU) / parseFloat(dcarPerDollar.value)
        ).toFixed(2);
      }
    };

    const checkIsDCAUApproved = async () => {
      let allowanceMarketplace = await DCAUContract.value.allowance(
        address.value,
        MarketplaceContract.address
      );
      allowanceMarketplace = ethers.utils.formatEther(allowanceMarketplace);
      if (
        parseFloat(allowanceMarketplace) >= parseFloat(item.value.priceInDCAU)
      ) {
        isDCAUApproved.value = true;
        // store.commit("marketplace/setIsDCAUApproved", false);
      } else {
        isDCAUApproved.value = false;
        // store.commit("marketplace/setIsDCAUApproved", true);
      }
    };

    const checkIsDCARApproved = async () => {
      let allowanceMarketplace = await DCARContract.value.allowance(
        address.value,
        MarketplaceContract.address
      );
      allowanceMarketplace = ethers.utils.formatEther(allowanceMarketplace);

      if (
        parseFloat(allowanceMarketplace) >= parseFloat(item.value.priceInDCAU) // The market data returns dcar price also as priceindcau. TODO need to fix this later
      ) {
        isDCARApproved.value = true;
        // store.commit("marketplace/setIsDCARApproved", false);
      } else {
        isDCARApproved.value = false;
        // store.commit("marketplace/setIsDCARApproved", true);
      }
    };

    const approveDCAU = async () => {
      isApprovingDCAU.value = true;
      try {
        let txApproveMarketplaceSpend = await DCAUContract.value.approve(
          MarketplaceContract.address,
          ethers.utils.parseEther("1000000000")
        );
        const receipt = await txApproveMarketplaceSpend.wait();
        if (receipt.status === 1) {
          const allowanceMarketplace = ethers.utils.formatEther(
            receipt.events[0].args.value
          );
          isDCAUApproved.value =
            parseFloat(allowanceMarketplace) >=
            parseFloat(item.value.priceInDCAU);
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
      isApprovingDCAU.value = false;
    };

    const approveDCAR = async () => {
      isApprovingDCAR.value = true;
      try {
        let txApproveMarketplaceSpend = await DCARContract.value.approve(
          MarketplaceContract.address,
          ethers.utils.parseEther("1000000000")
        );
        const receipt = await txApproveMarketplaceSpend.wait();
        if (receipt.status === 1) {
          const allowanceMarketplace = ethers.utils.formatEther(
            receipt.events[0].args.value
          );
          isDCARApproved.value =
            parseFloat(allowanceMarketplace) >=
            parseFloat(item.value.priceInDCAU);
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
      isApprovingDCAR.value = false;
    };
    /**
     * Watching messages.
     * After every change in message, scrolling to the bottom of the message area.
     * Also assigning read message to the latest message count if chat open
     */
    watch(
      searchSorting,
      (value) => {
        store.dispatch("marketplace/processSorting", value);
        getMarketItems();
      },
      { deep: true }
    );

    const setSearching = (item) => {
      toggleDropdown.value = true;
      searchSorting.value = item;
    };

    const buyEquipment = async (marketItemId, itemId, saleIndex) => {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const account = accounts.length > 0 ? accounts[0] : "";
      const sessionId = localStorage.getItem("sessionId");

      if (!account || !sessionId) {
        return;
      }

      try {
        // Doing the contract transaction
        // Executing Marketplace Listing Transaction
        const tx = await MarketplaceContract.buyNFT(
          ethers.utils.formatUnits(saleIndex, 0)
        );
        const receipt = await tx.wait();
        // If transaction successful, update game data
        if (receipt.status === 1) {
          updateBalances(); //Asynchronously updating token  balances in game
          const response = await fetch(
            Constants.apiUrl +
              "marketplace/buy-item?type=" +
              store.state.marketFilter +
              "&page=1" +
              "&sortBy=createdAt&sortOrder=desc",
            {
              method: "POST",
              body: JSON.stringify({
                sessionId,
                account,
                marketItemId,
                saleIndex,
                blockNumber: receipt.blockNumber,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const result = await response.json();
          store.commit("marketplace/setMarketData", result.marketplaceItems);
          store.commit("setInventory", result.inventory);
          // Mixpanel Tracking
          trackEvent("Marketplace - Buy Item", {
            itemId: item.value.itemId,
            type: item.value.itemType,
            name: item.value.name,
            rarity: item.value.rarity,
            quantity: item.value.quantity,
            price: item.value.priceInDCAU,
            seller: item.value.ownerAddress,
          });

          store.commit("setRemoveTooltip");
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
    };

    const processBuy = async () => {
      isBuying.value = true;
      const marketItemId = item.value._id;
      await buyEquipment(marketItemId, item.value.itemId, item.value.saleIndex);

      isBuying.value = false;
    };
    /**
     * By default always fetching page 1 of the respective type but maintaing the sort type
     */
    const searchTimeOut = () => {
      if (searchDelayTimer.value) {
        clearTimeout(searchDelayTimer.value);
        searchDelayTimer.value = null;
      }
      searchDelayTimer.value = setTimeout(() => {
        store.commit("marketplace/setSearchTerm", currentSearchTerm.value);
        getMarketItems();
      }, 500);
    };

    /**
     * By default always fetching page 1 of the respective type but maintaing the sort type
     */
    const getMarketItems = async () => {
      isLoading.value = true;
      await store.dispatch("marketplace/updateMarketplaceItems");
      isLoading.value = false;
      //   resetFilter();
    };

    const currentMarketInventory = computed(() => {
      let results = store.state.marketplace.marketData;
      return results;
    });

    const currentCategory = computed(() => {
      if (item.value && item.value.itemType === "consumable")
        return "consumable";
      else if (item.value && item.value.itemType === "resource")
        return "resource";
      else return "equipment";
    });

    const getItemImage = computed(() => {
      if (!item.value || !item.value.imageName) {
        return "";
      }
      if (item.value.itemType === "blueprint") {
        return (
          "https://ik.imagekit.io/dcg/blueprints/" +
          item.value.imageName +
          "?tr=w-200"
        );
      }
      return (
        "https://ik.imagekit.io/dcg/equip/" + item.value.imageName + "?tr=w-400"
      );
    });

    const isSessionActive = computed(() => {
      return store.state.isSessionActive;
    });

    return {
      // data
      item,
      currentMarketInventory,
      heroData,
      joepegs,
      hasId,
      error,
      id,
      isLoading,
      toggleFilter,
      searchSorting,
      sortList,
      toggleDropdown,
      currentSearchTerm,
      currentPriceDollar,
      isDCAUApproved,
      isDCARApproved,
      isBuying,
      isLoadingDollarPrice,
      isApprovingDCAU,
      isApprovingDCAR,
      isFetchingItem,
      // methods
      getMarketItems,
      setSearching,
      searchTimeOut,
      checkIsDCARApproved,
      checkIsDCAUApproved,
      approveDCAR,
      approveDCAU,
      processBuy,
      // computed
      currentCategory,
      getItemImage,
      isSessionActive,
    };
  },
};
</script>

<style lang="scss">
.single-marketplace-item {
  .blue-button {
    margin: 1rem 0;
  }
}
</style>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.header {
  grid-template-columns: 150px 1fr 2fr 200px;
  display: grid;
  padding: 1rem;
  align-items: center;
  grid-gap: 1rem;
  background: #171a1c;
  margin-bottom: 2rem;
  .logo {
    width: 150px;
  }
  .menu {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    list-style: none;
    li a {
      text-transform: uppercase;
      text-decoration: none;
    }
  }
  .search {
    #search {
      width: 90%;
      padding: 12px 10px;
      font-size: 1.1rem;
      background: #101214;
      box-shadow: none;
      border: none;
      border-radius: 10px;
    }
  }
}
.wrapper {
  display: block;
  max-width: 1616px;
  margin: 0 auto;
  padding: 0 1rem;

  .controls {
    display: grid;
    grid-template-columns: 1fr 250px;
    margin: 1rem 0;
    .button-controls {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
      grid-gap: 1rem;
      .button {
        width: 40px;
        height: 40px;
        background-size: 75%;
        padding: 5px;
        display: grid;
        align-items: center;
        justify-items: center;
        text-indent: -9999px;
        box-shadow: none;
        border: 2px solid #404649;
        background-color: transparent;
        border-radius: 10px;
        margin: 1rem 0 !important;
        &:hover:after {
          opacity: 1;
        }
        &:after {
          width: 30px;
          height: 30px;
          content: "";
          position: absolute;
          background-position: center center;
          background-repeat: no-repeat;
          opacity: 0.6;
        }
        &.filter:after {
          background-image: url("../../assets/ui/filter.svg");
        }
        &.refresh:after {
          background-image: url("../../assets/ui/refresh.svg");
        }
      }
    }

    .order {
      max-width: 250px;

      /* Dropdown */
      .aselect {
        width: 100%;
        z-index: 50;
        position: relative;

        .selector {
          border: 2px solid #404649;
          background-color: transparent;
          border-radius: 10px;
          position: relative;
          z-index: 1;

          .expanded {
            transform: rotateZ(180deg) translateY(2px);
          }
          .label {
            font-size: 16px;
            color: #888;
            min-height: 40px;
            display: grid;
            align-items: center;
            position: relative;
            :after {
              content: "";
              position: absolute;
              right: 20px;
              top: 40%;
              width: 0;
              height: 0;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 8px solid #ffffff7a;
              transform: rotateZ(0deg) translateY(0px);
              transition-duration: 0.3s;
              transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);
            }
          }
        }
        ul {
          width: 100%;
          list-style-type: none;
          padding: 0;
          margin: 0;
          font-size: 16px;
          border: 1px solid #404649;
          position: absolute;
          z-index: 1;
          background: #0f1215;
          border-radius: 10px;
          top: -3px;
        }
        li {
          padding: 12px;
          color: #666;
          &:hover {
            color: white;
            background: #393f43;
          }
        }
        .current {
          background: #eaeaea;
        }
        .hidden {
          visibility: hidden;
        }
        .visible {
          visibility: visible;
        }
      }
    }
  }
  .main-grid {
    display: grid;
    grid-template-columns: 400px 3fr;
    max-width: 1200px;
    margin: 0 auto;
    grid-gap: 1rem;
    span.rarity {
      display: block;
      font-family: "IM Fell English", serif;
      font-size: 1.1rem;
      margin-bottom: 2rem;
      max-width: 100px;
      &.shoddy {
        &::after {
          border: 1px solid $shoddy;
        }
      }

      &.epic {
        background-color: $epic;
        padding: 2px 10px;
        color: #000;
      }

      &.legendary {
        background-color: $legendary;
        padding: 2px 10px;
        color: #000;
      }

      &.mythical {
        background-color: $mythical;
        padding: 2px 10px;
        color: #000;
      }

      &.fabled {
        background-color: $fabled;
        padding: 2px 10px;
        color: #000;
      }

      &.unique {
        background-color: $unique;
        padding: 2px 10px;
        color: #000;
      }

      &.rare {
        background-color: $rare;
        padding: 2px 10px;
        color: #000;
      }

      &.artefact {
        background-color: $artefact;
        padding: 2px 10px;
        color: #000;
      }

      &.junk {
        &:after {
          border: 1px solid #000;
        }
      }

      &.uncommon {
        &:after {
          border: 1px solid $uncommon;
        }
      }
    }

    .details {
      padding: 0 2rem;
      h1 {
        font-size: 2rem;
        text-align: left;
      }
      .attributes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
      }

      span.price {
        font-size: 1.7rem;
        font-weight: bold;
        display: block;
        text-align: left;
        margin: 0 0 1rem;
        span {
          opacity: 0.5;
        }
        &:before {
          content: "";
          display: inline-block;
          width: 35px;
          height: 35px;
          line-height: 0;
          margin-top: -8px;
          padding: 0;
          top: 7px;
          position: relative;
          margin-right: 4px;
        }
        &.DCAR:before {
          background: url("../../assets/ui/dcar.png") no-repeat left center;
          background-size: contain;
        }
        &.DCAU:before {
          background: url("../../assets/ui/dcau.png") no-repeat left center;
          background-size: contain;
        }
      }
      span.dollar-value {
        font-size: 1rem;
        display: inline-block;
        margin: 2px 0 0 5px;
        color: rgba(255, 255, 255, 0.5215686275);
      }
      span.skeleton-box {
        display: inline-block !important;
        top: 3px;
        position: relative;
        margin-left: 5px;
        opacity: 0.2;
      }
    }
    .button-container {
      display: grid;
      margin-bottom: 1rem;
    }
  }
  .mini-popup {
    background-image: none;
    border-image-source: none;
    border: none;
    padding: 1rem;
    border-radius: 10px;
    background-color: #111416;

    div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      &.rarity {
        margin-bottom: 10px;
        text-align: center;
        display: block;
        span {
          display: block;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
      &.meta {
        span {
          font-family: "Lato", sans-serif;
          /* background-color: #00000049; */
          padding: 5px 10px;
          display: block;
          margin-bottom: 2px;
          &.value {
            text-align: center;
            background-color: #5b5b5b49;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
</style>
